import { useState, useContext } from "react";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {  useNavigate } from "react-router-dom";
import Preloader from "./Preloader";
import { AuthContext } from "../context/AuthContext";
function Login() {
  const [preloader, setpreloader] = useState(false);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [ButtonName, setButtonName] = useState("Send OTP");
  const [OtpContainer, setOtpContainer] = useState(false);
  const { isAuthenticated, login } = useContext(AuthContext);
  const navigate = useNavigate();
  const message = (status, text) => {
    if (status === 1) {
      toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (status === 0) {
      toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (status === 2) {
      toast.warning(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleLogin = () => {
    if (username === "") {
      message(0, "Please enter Mobile Number.");
      return;
    }
    if (!OtpContainer) {
      let data = {
        mobile: username,
      };
      setpreloader(true);
      login(data, function (response) {
        if (response.status === 0) {
          setpreloader(false);
          message(0, response.msg);
        } else {
          setOtpContainer(true);
          setButtonName("Verify OTP & Login");
          setpreloader(false);
          message(1, response.msg);
        }
      });
      return;
    } else {
      if (password === "") {
        message(0, "Please enter OTP.");
        return;
      } else {
        let data = {
          mobile: username,
          otp: password,
        };
        setpreloader(true);
        login(data, function (response) {
          if (response.status === 0) {
            setpreloader(false);
            message(0, response.msg);
          } else {
            setpreloader(false);
            message(1, response.msg);
          }
        });
        return;
      }
    }
  };
  if (isAuthenticated) {
    navigate("/dashboard");
  }
  return (
    <>
      <Preloader status={preloader} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <motion.div
        initial={{ translateX: -100 }}
        animate={{ translateX: 0 }}
        exit={{ translateX: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className="aghorilogocontainer">
          <img src="/cashwinmoney.png" className="aghorilogo" alt="Logo" />
        </div>
        <p className="texttitle">AGHORI MATKA</p>
        <div className="recharge_box">
          <div className="input_box">
            <img src="/icons_asset/mobile.png" alt="" />
            <input
              type="text"
              placeholder="Enter Phone Number"
              onInput={(event) => {
                setusername(event.target.value);
              }}
            />
            <span className="tips_span">Phone Number is required</span>
          </div>
          {OtpContainer ? (
            <div className="input_box">
              <img src="/icons_asset/key.png" alt="" />
              <input
                type="tel"
                placeholder="OTP"
                onInput={(event) => {
                  setpassword(event.target.value);
                }}
              />
              <span className="tips_span">OTP is required</span>
            </div>
          ) : (
            ""
          )}
          <div className="input_box_btn">
            <button className="login_btn ripple" onClick={handleLogin}>
              {ButtonName}
            </button>
          </div>
          <div className="input_box_btn">
            <button className="login_btn ripple color-green">
              हेल्प चाहिए WHATSAPP करो
            </button>
          </div>
          <div className="input_box_btn">
            <button className="login_btn ripple color-blue">
              हेल्प चाहिए TELEGRAM करो
            </button>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Login;
