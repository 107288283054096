import React from 'react';

const Table = ({ key, data }) => {
  function capitalize(str) {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div>
      <div className="trn-container" key={key}>
        <div className="left" style={{width:'90%'}}>
          <p>{capitalize(data.description)}</p>
          <p>{data.date+" "+data.time}</p>
        </div>
        <div className="right" style={{width:'max-content'}}>
          <p>₹ {data.add_amount}</p>
        </div>
      </div>
    </div>
  );
};

export default Table;
