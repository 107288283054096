import { useState, useContext, useEffect } from 'react';
import { motion } from "framer-motion";
import Footer from '../../includes/footer-tab';
import Preloader from '../Preloader';
import BackButton from '../../includes/Back_btn';
import { AuthContext } from '../../context/AuthContext';

function RateList() {
    const [preloader, setpreloader] = useState(true);
    const [Data, setData] = useState("");
    const { GameRule } = useContext(AuthContext);
    useEffect(() => {
        GameRule(function (response) {
            setpreloader(false);
            if (response.status === 0) {
                // message(0, response.msg);
            } else {
                setData(response.data.description);
            }
        });
    }, [GameRule])
    return (
        <>
            <Preloader status={preloader} />
            <motion.div
                initial={{ translateX: -100 }}
                animate={{ translateX: 0 }}
                exit={{ translateX: 0 }}
                transition={{ duration: 0.2 }}
            >
                <div className="login header">
                    <div className="item">
                        <BackButton />
                        <p>Rate List</p>
                    </div>
                </div>
                <div data-v-4af9315c="" className="content" dangerouslySetInnerHTML={{ __html: Data }}>
                    
                </div>



            </motion.div>
            <Footer page='login' />
        </>
    );
}

export default RateList;