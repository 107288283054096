import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaHistory,FaShareAlt } from "react-icons/fa";
import { MdHome } from "react-icons/md";
import { AuthContext } from '../context/AuthContext';
function Footer({ page }) {
  const { app_url } = useContext(AuthContext);
  return (
    <>
      <div className="footer-tab">
        <Link to='/dashboard' className='footer-btn-container'>
          <MdHome />
          <p>Home</p>
        </Link>
        {localStorage.getItem('login') && localStorage.getItem('login') !== '' ?
        <Link to='/history/all' className='footer-btn-container'>
          <FaHistory />
          <p>History</p>
        </Link>
        : '' }
        {localStorage.getItem('login') && localStorage.getItem('login') !== '' ?
        <Link to={'https://api.whatsapp.com/send/?text=Share+This+App+With+your+Friends+and+family+to+Get+10+Referral+Bonus+on+New+Register+Account%0A%0Ahttps%3A%2F%2F'+app_url+'&type=phone_number&app_absent=0'} className='footer-btn-container'>
          <FaShareAlt/>
          <p>Share</p>
        </Link>
        : '' }
        <Link to={localStorage.getItem('login') && localStorage.getItem('login') !== '' ? '/mine' : '/login' } className='footer-btn-container'>
          <img src={'/icons_asset/support.png'} alt="Help" />
          <p>Help</p>
        </Link>
      </div>
    </>
  )
}

export default Footer;
