import { React, useState, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../context/AuthContext";
import { useSwipeable } from "react-swipeable";
import GamePageLayout from "./GamePageLayout";

function Playground() {
  const { id } = useParams();
  const [values, setValues] = useState(Array(100).fill(""));
  const [Harufvalues, HarufsetValues] = useState(Array(10).fill(""));
  const [Baharvalues, BaharsetValues] = useState(Array(10).fill(""));
  const { isAuthenticated, Addbet } = useContext(AuthContext);
  const [new_message, setnew_message] = useState("");
  const navigate = useNavigate();
  if (!isAuthenticated) {
    navigate("/login");
  }
  const message = (status, text) => {
    return alert(text);
    if (status === 1) {
      toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (status === 0) {
      toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (status === 2) {
      toast.warning(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  //Generate Rows of jodi matka
  const rows = [];
  const anderHarufrows = [];
  const baharHarufrows = [];

  // Generate rows up to 100
  for (let i = 0; i < 10; i++) {
    const headers = [];
    const headersHaruf = [];
    const inputs = [];
    const Anderinputs = [];
    const Baharinputs = [];
    for (let j = 1; j <= 10; j++) {
      const number = i * 10 + j;
      const index = i * 10 + j - 1;
      headers.push(
        <th key={`th-${number}`}>{number < 10 ? `0${number}` : number}</th>
      );
      inputs.push(
        <td key={`td-${number}`}>
          <input
            type="number"
            min="1"
            step="1"
            value={values[index]}
            onChange={(e) => handleInputChange(index, e.target.value, "jodi")}
          />
        </td>
      );
      if (i === 0) {
        headersHaruf.push(<th key={`th-${number - 1}`}>{number - 1}</th>);
        Anderinputs.push(
          <td key={`td-${number}`}>
            <input
              type="number"
              min="1"
              step="1"
              value={Harufvalues[index]}
              onChange={(e) =>
                handleInputChange(index, e.target.value, "ander")
              }
            />
          </td>
        );
        Baharinputs.push(
          <td key={`td-${number}`}>
            <input
              type="number"
              min="1"
              step="1"
              value={Baharvalues[index]}
              onChange={(e) =>
                handleInputChange(index, e.target.value, "bahar")
              }
            />
          </td>
        );
      }
    }
    if (i === 0) {
      anderHarufrows.push(
        <>
          <tr>{headersHaruf}</tr>
          <tr>{Anderinputs}</tr>
        </>
      );
      baharHarufrows.push(
        <>
          <tr>{headersHaruf}</tr>
          <tr>{Baharinputs}</tr>
        </>
      );
    }
    rows.push(
      <>
        <tr>{headers}</tr>
        <tr>{inputs}</tr>
      </>
    );
  }
  // Handle input change
  const handleInputChange = (index, newValue, purpose) => {
    const updatedValues = [...values];
    const AnderupdatedValues = [...Harufvalues];
    const BaharupdatedValues = [...Baharvalues];
    if (purpose === "jodi") {
      updatedValues[index] = newValue ? parseInt(newValue, 10) : ""; // Ensure values are integers or empty
      setValues(updatedValues);
    }
    if (purpose === "ander") {
      AnderupdatedValues[index] = newValue ? parseInt(newValue, 10) : ""; // Ensure values are integers or empty
      HarufsetValues(AnderupdatedValues);
    }
    if (purpose === "bahar") {
      BaharupdatedValues[index] = newValue ? parseInt(newValue, 10) : ""; // Ensure values are integers or empty
      BaharsetValues(BaharupdatedValues);
    }
  };

  // Calculate the sum of all values
  const calculateSum = () => {
    let Sum = values.reduce((sum, value) => sum + (value || 0), 0); // Add non-empty values
    Sum += Harufvalues.reduce((sum, value) => sum + (value || 0), 0); // Add non-empty values
    Sum += Baharvalues.reduce((sum, value) => sum + (value || 0), 0); // Add non-empty values

    return Sum;
  };
  const calculateSu = (purpose) => {
    if (purpose === 1) {
      return values.reduce((sum, value) => sum + (value || 0), 0);
    }
    if (purpose === 2) {
      return Harufvalues.reduce((sum, value) => sum + (value || 0), 0);
    }
    if (purpose === 3) {
      return Baharvalues.reduce((sum, value) => sum + (value || 0), 0);
    }
  };
  const handleBetting = () => {
    if (values.length === 0) {
      message(0, "Empty Response");
      return;
    }
    let AllGameCount = 0;

    const transformedValuesJodi = values
      .map((value, index) => ({ bet_numbers: index + 1, bet_money: value }))
      .filter((entry) => entry.bet_money !== "");

    const transformedValuesAnderHaruf = Harufvalues.map((value, index) => ({
      bet_numbers: index + 1,
      bet_money: value,
    })).filter((entry) => entry.bet_money !== "");

    const transformedValuesBaharHaruf = Baharvalues.map((value, index) => ({
      bet_numbers: index + 1,
      bet_money: value,
    })).filter((entry) => entry.bet_money !== "");

    if (transformedValuesJodi.length > 0) {
      AllGameCount++;
    }
    if (transformedValuesAnderHaruf.length > 0) {
      AllGameCount++;
    }
    if (transformedValuesBaharHaruf.length > 0) {
      AllGameCount++;
    }
    if (transformedValuesJodi.length > 0) {
      let grandTotal = calculateSu(1);
      Addbet(
        id,
        1,
        JSON.stringify(transformedValuesJodi),
        grandTotal,
        (res) => {
          if (res.status === 0) {
            message(0, res.msg);
            return;
          }
          setnew_message(res.msg);
          // message(1, res.msg);
          AllGameCount--;
          if(AllGameCount == 0){
            setTimeout(() => {
              navigate("/dashboard");
            }, 1000);
          }
          setValues([]);
        }
      );
    }
    if (transformedValuesAnderHaruf.length > 0) {
      let grandTotal = calculateSu(2);
      Addbet(
        id,
        2,
        JSON.stringify(transformedValuesAnderHaruf),
        grandTotal,
        (res) => {
          if (res.status === 0) {
            message(0, res.msg);
            return;
          }
          // message(1, res.msg);
          setnew_message(res.msg);
          AllGameCount--;
          if(AllGameCount == 0){
            setTimeout(() => {
              navigate("/dashboard");
            }, 1000);
          }
          HarufsetValues([]);
        }
      );
    }
    if (transformedValuesBaharHaruf.length > 0) {
      let grandTotal = calculateSu(3);
      Addbet(
        id,
        3,
        JSON.stringify(transformedValuesBaharHaruf),
        grandTotal,
        (res) => {
          if (res.status === 0) {
            message(0, res.msg);
            return;
          }
          // message(1, res.msg);
          setnew_message(res.msg);
          AllGameCount--;
          if(AllGameCount == 0){
            setTimeout(() => {
            navigate("/dashboard");
            }, 1000);
          }
          BaharsetValues([]);
        }
      );
    }
  };
  const handlers = useSwipeable({
    onSwipedLeft: () => navigate("/crossing/" + id),
  });
  return (
    <>
      <GamePageLayout handlers={handlers} id={id} new_message={new_message}>
        <div className="game-list">
          <Link to={"/play/" + id} className="games active">
            JODI
          </Link>
          <Link to={"/crossing/" + id} className="games">
            CROSSING
          </Link>
          <Link to={"/copypaste/" + id} className="games">
            COPY PASTE
          </Link>
        </div>
        {/* bet form design */}
        <table className="JODITable">
          <tbody>{rows}</tbody>
        </table>
        <p className="HarufHeading">Ander Haruf</p>
        <table className="JODITable">
          <tbody>{anderHarufrows}</tbody>
        </table>
        <p className="HarufHeading">Bahar Haruf</p>
        <table className="JODITable">
          <tbody>{baharHarufrows}</tbody>
        </table>
        <div className="SumOfAmount">
          <h3> ₹ {calculateSum()}</h3>
          <button onClick={handleBetting}>PLAY</button>
        </div>
      </GamePageLayout>
    </>
  );
}

export default Playground;
