import React from 'react';

const MarketTable = ({ key, data }) => {
  return (
    <div>
      <div className="trn-container" key={key}>
        <div className="left" style={{ width: '80%' }}>
          <p>{data.game_name} - {data.type === "1" ? "JODI" : (data.type === "2" ? " ANDER H" : "BAHAR H")} - {data.game_number}</p>
          <p>{data.created_at}</p>
        </div>
        <div className="right" style={{ width: 'max-content',textAlign:'right'}}>
          <p>₹ {data.money}</p>
        </div>
      </div>
    </div>
  );
};

export default MarketTable;
