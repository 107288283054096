import { React, useState, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../context/AuthContext";
import { useSwipeable } from 'react-swipeable';
import GamePageLayout from "./GamePageLayout";

function CopyPaste() {
  const { id } = useParams();
  const [values, setValues] = useState([]);
  const [DigitInput, setDigitInput] = useState("");
  const [AmountInput, setAmountInput] = useState("");
  const [Repetition, setRepetition] = useState(1);
  const {Addbet} = useContext(AuthContext);
  const navigate = useNavigate();
  const [new_message, setnew_message] = useState("");
  const message = (status, text) => {
    if (status === 1) {
      toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (status === 0) {
      toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (status === 2) {
      toast.warning(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // Calculate the sum of all values
  const calculateSum = () => {
    return values.length*AmountInput;
  };

  const generateArray = (digitInput, repetition) => {
    let digitString = digitInput.toString();

    // Ensure digit count is even
    if (digitString.length % 2 !== 0) {
      return [0, [1, 2], "Fill Digit Based On Even(2)"];
    }

    const resultArray = [];
    // resultArray.push(parseInt(digitString[0], 10)); // First pair is the first digit

    for (let i = 0; i < digitString.length; i += 2) {
      const pair = digitString[i] + digitString[i + 1];

      if (repetition === 1) {
        resultArray.push(parseInt(pair, 10)); // Add the pair directly
      } else if (repetition === 0) {
        const reversedPair = digitString[i + 1] + digitString[i];
        resultArray.push(parseInt(pair, 10), parseInt(reversedPair, 10)); // Add pair and reversed pair
      }
    }

    return [1, resultArray, "success"];
  };
  const handleDelete = (index) => {
    setValues(values.filter((_, i) => i !== index));
  };
  const renderRows = () => {
    const rows = [];
    values.forEach((element, index) => {
      rows.push(
        <tr key={index}>
          <td>{element}</td>
          <td>{AmountInput}</td>
          <td>
            <button onClick={() => handleDelete(index)}>Delete</button>
          </td>
        </tr>
      );
    });
    return rows;
  };
  const handleCopyPaste = () => {
    if (DigitInput === "") {
      message(0, "Please Enter Digit ");
      return;
    } else if (AmountInput === "") {
      message(0, "Please Enter Amount ");
      return;
    }

    const ArrayOutput = generateArray(DigitInput, Repetition);
    if (ArrayOutput[0] === 0) {
      message(0, ArrayOutput[2]);
      setValues([]);
      return;
    }
    setValues(ArrayOutput[1]);
  };
  const handleBetting = () => {
    if(values.length === 0){
      message(0, "Empty Response");
      return;
    }
    const transformedValues = values.map((value) => ({
      bet_numbers: value,
      bet_money: AmountInput,
    }));
    let grandTotal = values.length*AmountInput;
    Addbet(id,1,JSON.stringify(transformedValues),grandTotal, (res) => {
      if(res.status === 0){
        setnew_message(res.msg);
        return;
      }
      setnew_message(res.msg);
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
      setValues([]);
    });
  }
  const handlers = useSwipeable({
    onSwipedRight: () => navigate("/crossing/" + id)
  });
  return (
    <>
      <GamePageLayout handlers={handlers} id={id} new_message={new_message}>
        <div className="game-list">
          <Link to={"/play/" + id} className="games">
            JODI
          </Link>
          <Link to={"/crossing/" + id} className="games">
            CROSSING
          </Link>
          <Link to={"/copypaste/" + id} className="games active">
            COPY PASTE
          </Link>
        </div>
        {/* bet form design */}
        <div className="CopypASTE-container">
          <label class="custom-field one">
            <input type="tel"
              onInput={(event) => {
                setDigitInput((event.target.value));
              }}
              placeholder="&nbsp;"
              value={DigitInput}/>
            <span class="placeholder">Enter Digit</span>
            <span class="border"></span>
          </label>
          <label class="custom-field one">
            <input type="tel"
              onInput={(event) => {
                setAmountInput((event.target.value));
              }}
              placeholder="&nbsp;"
              value={AmountInput}/>
            <span class="placeholder">Enter Amount</span>
            <span class="border"></span>
          </label>
          
          <div className="input_box mt-5">
            <label htmlFor="witpalti">With Palti</label>
            <input
              type="radio"
              id="witpalti"
              name="repetition"
              placeholder="Enter Amount"
              className="width-20cent"
              onClick={(event) => {
                setRepetition(0);
              }}
              value={Repetition}
            />
          </div>
          <div className="buttonCollection">
            <button onClick={handleCopyPaste} className="m-0">
              <span>Play</span>
            </button>
          </div>
        </div>

        {values.length > 0 ? (
          <table className="JODITable">
            <tr>
              <th>NUMBER</th>
              <th>AMOUNT</th>
              <th>ACTION</th>
            </tr>
            <tbody>
            {renderRows()}
            </tbody>
          </table>
        ) : ''}

        <div className="SumOfAmount">
          <h3> ₹ {calculateSum()}</h3>
          <button onClick={handleBetting}>PLAY</button>
        </div>
      </GamePageLayout>
    </>
  );
}

export default CopyPaste;
