import React from 'react';
import ReactDOM from 'react-dom/client';
import Playground from './components/Playground';
import CopyPaste from './components/CopyPaste';
import Crossing from './components/Crossing';
import Mine from './components/mine';
import Login from './components/Login';
import Dashboard from './components/Dashboard';

import Privacypolicy from './components/pages/Privacy_policy';
import RateList from './components/pages/RateList';
import Riskaggrement from './components/pages/Riskaggrement';
import Register from './components/Register';
import BankCard from './components/pages/bank_card';
import Promotion from './components/pages/Promotion';
import Recharge from './components/pages/Recharge';
import ResetPassword from './components/pages/ResetPassword';
import P2PTransfer from './components/pages/P2PTransfer';
import Transaction from './components/history/Transaction';
import Withdrawal from './components/pages/Withdrawal';
import ForgotPassword from './components/Forgot_password';
import AddBankCard from './components/pages/AddBankCard';
import './assets/css/App.css';
import './assets/css/responsive.css';
import './assets/css/AdarshCustomization.css';
import 'react-toastify/dist/ReactToastify.css';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { AnimatePresence } from "framer-motion";
// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://25e3fa8ebb8c4c23b2de094d1ee48659@o4505315573825536.ingest.sentry.io/4505315575332864",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <AuthProvider>
      <Router>
        <AnimatePresence mode='wait'>
          <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/register/:refcode?" exact element={<Register />} />
            <Route path="/forgot-password" exact element={<ForgotPassword />} />
            <Route path="/dashboard" exact element={<Dashboard />} />
            <Route path="/play/:id" exact element={<Playground />} />
            <Route path="/copypaste/:id" exact element={<CopyPaste />} />
            <Route path="/crossing/:id" exact element={<Crossing />} />
            {/* <Route path="/game-ground" exact element={<App />} /> */}
            <Route path="/mine" exact element={<Mine />} />
            {/* History Route  */}
            <Route path="/history/:id" exact element={<Transaction />} />
            {/* Pages Route  */}
            <Route path="/p2p" exact element={<P2PTransfer />} />
            <Route path="/recharge" exact element={<Recharge />} />
            <Route path="/withdrawal" exact element={<Withdrawal />} />
            <Route path="/promotion" exact element={<Promotion />} />
            <Route path="/bank-card" exact element={<BankCard />} />
            <Route path="/add-bank-card" exact element={<AddBankCard />} />
            <Route path="/reset-password" exact element={<ResetPassword />} />
            <Route path="/ratelist" exact element={<RateList />} />
            <Route path="/privacy-policy" exact element={<Privacypolicy />} />
            <Route path="/risk-disclouser-aggrement" exact element={<Riskaggrement />} />
          </Routes>
        </AnimatePresence>
      </Router>
    </AuthProvider>
  // </React.StrictMode>
);
reportWebVitals();