import { useState, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../includes/footer-tab";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineAddCard } from "react-icons/md";
import { GrAnnounce } from "react-icons/gr";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaChartLine, FaPlay } from "react-icons/fa";
import { AuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import Preloader from "./Preloader";
import { GrMenu } from "react-icons/gr";
import { MdNotificationsActive } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function Dashboard() {
  const [preloader, setpreloader] = useState(true);
  const [MarketData, setMarketData] = useState([]);
  const [UpcommingMarketData, setUpcommingMarketData] = useState([]);
  const [Walletd, setWalletd] = useState(0);
  const { app_name, app_url, getMarkets,isAuthenticated, profile } = useContext(AuthContext);
    const navigate = useNavigate();
    if (!isAuthenticated) {
        navigate('/login')
    }
  const message = (status, text) => {
    if (status === 1) {
      toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (status === 0) {
      toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (status === 2) {
      toast.warning(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    getMarkets(function (response, boolRes) {
      setpreloader(false);
      if (!boolRes && response.response.status !== 200) {
        message(0, response.response.data.error);
        // return;
      } else if (response.status === 0) {
        message(0, response.msg);
      } else {
        setMarketData(response.running_game);
        setUpcommingMarketData(response.upcomming_game);
      }
    });
    profile(function (response) {
      setpreloader(false);
      if (response.status === 0) {
        message(0, response.msg);
      } else {
        let walletdata = response.data[0].wallet;
        setWalletd(walletdata);
      }
    });
  }, [getMarkets,profile]);
  return (
    <div className="welcome">
      <Preloader status={preloader} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <motion.div
        initial={{ translateX: -100 }}
        animate={{ translateX: 0 }}
        exit={{ translateX: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className="welcome header">
          <Link to="/mine" className="HeaderMenuIcon">
            <GrMenu />
          </Link>
          <div className="part1">
            <p className="HeaderText text">{app_name}</p>
          </div>
          <Link to="" className="WalletInHeader">
            {Walletd} ₹
          </Link>
          <Link to="" className="download">
            <MdNotificationsActive />
          </Link>
        </div>
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          showArrows={false}
          autoPlay={true}
        >
          <div>
            <img src="/banner/a0626ed51ae9bb8216520edd7c32e1aa.png" alt="help"/>
          </div>
        </Carousel>
        <div className="buttonCollection">
        <button 
          onClick={() => {
            navigate(`https://api.whatsapp.com/send/?text=Share+This+App+With+your+Friends+and+family+to+Get+10+Referral+Bonus+on+New+Register+Account%0A%0Ahttps%3A%2F%2F${app_url}&type=phone_number&app_absent=0`);
          }}
        >
            <FaWhatsapp /> <span>WHATSAPP</span>
        </button>
        <button 
          onClick={() => {
            navigate('/recharge');
          }}
        >
            <MdOutlineAddCard />
            <span>ADD FUND</span>
          </button>
          <button 
          onClick={() => {
            navigate('/withdrawal');
          }}
        >
            <BiMoneyWithdraw />
            <span>WITHDRAW</span>
          </button>
        </div>
        <div className="buttonCollection">
          <button className="m-0">
            <span>HOW TO PLAY</span>
          </button>
        </div>
        <p className="notice">
          <GrAnnounce />
          <marquee>
            पैसे निकालने की रिक्वेस्ट के बाद कम से कम 2 से 3 मिनट वेट करें
          </marquee>
        </p>
        <div className="bazar">
          {UpcommingMarketData.map((market) => (
            <div className="item" key={market.game_id}>
              <div className="part partA">
                <p className="name">{market.game_name.toUpperCase()}</p>
                <div className="gameChartResult">
                  <p className="ResultNumber" style={{color: market.result == "XX" ? "" :"green"}}>{market.result}</p>
                  <FaChartLine />
                  <span>Game Chart</span>
                </div>
                <Link className="PlayTimeOut">TIME OUT</Link>
              </div>
              <div className="part partB">
                <p>OPEN: {market.start_time}</p>
                <p>CLOSE: {market.end_time}</p>
              </div>
            </div>
          ))}
          {MarketData.map((market) => (
            <div className="item" key={market.game_id}>
              <div className="part partA">
                <p className="name">{market.game_name.toUpperCase()}</p>
                <div className="gameChartResult">
                  <p className="ResultNumber" style={{color: market.result == "XX" ? "" :"green"}}>{market.result}</p>
                  <FaChartLine />
                  <span>Game Chart</span>
                </div>
                <Link to={"/play/"+market.game_id} className="PlayTimeIn">
                PLAY <FaPlay />
              </Link>
              </div>
              <div className="part partB">
                <p>OPEN: {market.start_time}</p>
                <p>CLOSE: {market.end_time}</p>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
      <Footer page="home" />
    </div>
  );
}

export default Dashboard;
