import { useState, useEffect, useContext } from 'react';
import { motion } from "framer-motion";
import { ToastContainer, toast } from 'react-toastify';
import { TiTick } from "react-icons/ti";
import { AiOutlineHistory } from "react-icons/ai";
import Footer from '../../includes/footer-tab';
import Preloader from '../Preloader';
import BackButton from '../../includes/Back_btn';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';

function Withdrawal() {
    const navigate = useNavigate();
    const { isAuthenticated, BankCardData, WithdrawalRequest } = useContext(AuthContext);
    const [preloader, setpreloader] = useState(true);
    const [Balance, setBalance] = useState('Loading..');
    const [Password, setPassword] = useState('');
    const [Amount, setAmount] = useState('');
    const [BankData, setBankData] = useState([]);
    const [BankCard, setBankCard] = useState('');
    const [Fees, setFees] = useState(0);
    const [FinalAmount, setFinalAmount] = useState(0);
    const [Minimum, setMinimum] = useState('Loading...');
    if (!isAuthenticated) {
        navigate('/login')
    }
    const message = (status, text) => {
        if (status === 1) {
            toast.success(text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else if (status === 0) {
            toast.error(text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else if (status === 2) {
            toast.warning(text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }
    const handleRecharge = () => {
        if (Amount === '') {
            message(0, 'Please enter Amount');
        } else if (Password === '') {
            message(0, 'Please enter Password');
        } else if (BankCard === '') {
            message(0, 'Select Bank detail');
        } else if (Amount > Balance) {
            message(0, 'Insufficient Wallet balance');
        } else if (Amount < Minimum) {
            message(0, 'Please enter amount greater than ' + Minimum);
        } else {
            let data = {
                bankcard: BankCard,
                amount: Amount,
                password: Password
            };
            setpreloader(true);
            WithdrawalRequest(data, (response) => {
                setpreloader(false);
                if (response.error) {
                    message(0, response.status);
                } else {
                    message(1, 'Your withdrawal successfully submitted.');
                    setTimeout(() => {
                        navigate('/mine')
                    }, 2000);
                }
            });
        }
    }
    const inputAmount = (event) => {
        let amount = parseInt(event.target.value);
        let tax = (amount / 100 * 5);
        setAmount(amount);
        setFees(tax);
        setFinalAmount(amount - tax);
    }
    useEffect(() => {
        BankCardData((data) => {
            setpreloader(false);
            if (data.error) {
                message(0, data.message);
            } else {
                setBalance(Math.floor(data[0].balance));
                setMinimum(data[1].minimum_withdrawal);
                setBankData(data[0]);
            }
        });
    }, [BankCardData])

    return (
        <>
            <Preloader status={preloader} />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <motion.div
                initial={{ translateX: -100 }}
                animate={{ translateX: 0 }}
                exit={{ translateX: 0 }}
                transition={{ duration: 0.2 }}
            >

                <div className="login header">
                    <div className="item">
                        <BackButton />
                        <p>Withdrawal</p>
                        <Link to="/history/withdrawal" className="historyright"><AiOutlineHistory /></Link>
                    </div>
                </div>
                <div className="recharge recharge_box">
                    <div className='bonus-tag'>Balance:₹ {Balance}</div>
                    <div className="input_box">
                        <img
                            src='/icons_asset/Withdrawal.png' alt=""
                        />
                        <input type="text" placeholder="Enter Amount" onInput={(event) => { inputAmount(event) }} value={Amount} />
                    </div>
                    <p className='notice'>Fees {Fees}, to account {FinalAmount}</p>
                    <div className="agree_box payout_box">
                        <p className='notice'>Payout:</p>
                        <div role="checkbox" tabIndex="0" aria-checked="true" className="van-checkbox">
                            <div className="">
                                <i className="van-icon van-icon-success">
                                    <TiTick />
                                </i>
                            </div>
                            <span className="van-checkbox__label">Bank Card</span>
                        </div>
                    </div>
                    <div className="input_box">
                        <img
                            src='/icons_asset/card.png' alt=""
                        />
                        { 
                        BankData.account === 1111111111 ?
                            <Link to="/add-bank-card">Add bank card</Link> :
                            <select onChange={(event) => { setBankCard(event.target.value) }}>
                                <option value="">Select Bank card</option>
                                <option value={BankData.account}>{BankData.bankname + ' | ' + BankData.account}</option>
                            </select>
                        }
                    </div>
                    <div className="input_box">
                        <img
                            src='/icons_asset/key.png' alt=""
                        />
                        <input type="password" placeholder="Enter Password" onInput={(event) => { setPassword(event.target.value) }} value={Password} />
                    </div>
                    <p className='notice'>Note: Minimum Withdrawal amount is ₹{Minimum}</p>
                    <div className="input_box_btn">
                        <button className="login_btn ripple" onClick={handleRecharge}>
                            Withdrawal
                        </button>
                    </div>
                </div>
            </motion.div>
            <Footer page='recharge' />
        </>
    );
}

export default Withdrawal;